import React, { useState, useEffect } from 'react';
import './App.css';
import WeatherCard from './components/WeatherCard';

function App() {
  const [weatherData, setWeatherData] = useState([]);
  const [currentWeek, setCurrentWeek] = useState(12);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchWeatherData();
  }, []);

  const fetchWeatherData = async () => {
    try {
      const response = await fetch(
        `https://nfl-snowlerts.s3.amazonaws.com/week${currentWeek}/weather.json`,
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setWeatherData(data);
      setLoading(false);
    } catch (err) {
      console.error('Error details:', err);
      setError('Failed to fetch weather data');
      setLoading(false);
    }
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="app">
      <header className="app-header">
        <h1>
          <span className="football-icon">🏈</span>
          NFL Snowlerts
          <span className="snow-icon">❄️</span>
        </h1>
        <h2>Week {currentWeek}</h2>
      </header>
      <div className="weather-grid">
        {weatherData.map((game, index) => (
          <WeatherCard key={index} game={game} />
        ))}
      </div>
    </div>
  );
}

export default App;
