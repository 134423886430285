import React from 'react';
import './WeatherCard.css';

function WeatherCard({ game }) {
    const formatTime = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            timeZoneName: 'short'
        });
    };

    const getWeatherType = () => {
        const description = game.weather.weather[0].description.toLowerCase();
        const temp = game.weather.main.temp;

        if (description.includes('snow')) {
            return 'snow';
        } else if (description.includes('rain') || description.includes('drizzle')) {
            return 'rain';
        } else if (temp <= 32) {
            return 'freezing';
        } else if (temp >= 85) {
            return 'hot';
        } else if (description.includes('clear')) {
            return 'clear';
        } else if (description.includes('cloud')) {
            return 'cloudy';
        }
        return 'default';
    };

    const getWeatherIcon = (type) => {
        const icons = {
            snow: '❄️',
            rain: '🌧️',
            freezing: '🥶',
            hot: '🔥',
            clear: '☀️',
            cloudy: '☁️',
            default: '🌡️'
        };
        return icons[type] || icons.default;
    };

    const getWeatherLabel = (type) => {
        const labels = {
            snow: 'SNOW GAME',
            rain: 'RAIN GAME',
            freezing: 'FREEZING COLD',
            hot: 'HOT WEATHER',
            clear: 'CLEAR SKIES',
            cloudy: 'CLOUDY',
            default: ''
        };
        return labels[type];
    };

    const weatherType = getWeatherType();
    const weatherIcon = getWeatherIcon(weatherType);
    const weatherLabel = getWeatherLabel(weatherType);

    return (
        <div className={`weather-card ${weatherType}-weather`}>
            {weatherLabel && (
                <div className={`weather-indicator ${weatherType}-indicator`}>
                    {weatherIcon} {weatherLabel} {weatherIcon}
                </div>
            )}
            <div className="game-info">
                <h3>{game.shortName}</h3>
                <p className="game-time">{formatTime(game.gameTime)}</p>
                <div className="teams">
                    <div className="team away">
                        <span className="team-name">{game.awayTeam.displayName}</span>
                    </div>
                    <div className="vs">@</div>
                    <div className="team home">
                        <span className="team-name">{game.homeTeam.displayName}</span>
                    </div>
                </div>
                <p className="location">{game.city}, {game.state}</p>
            </div>
            <div className="weather-info">
                <div className="temperature">
                    <span className="temp-value">{Math.round(game.weather.main.temp)}°F</span>
                    <span className="weather-icon">{weatherIcon}</span>
                </div>
                <div className="weather-details">
                    <p>Feels like: {Math.round(game.weather.main.feels_like)}°F</p>
                    <p>Humidity: {game.weather.main.humidity}%</p>
                    <p>Wind: {Math.round(game.weather.wind.speed)} mph</p>
                    <p className={`conditions ${weatherType}-conditions`}>
                        Conditions: {game.weather.weather[0].description}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default WeatherCard;